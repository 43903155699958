// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';

// const FileViewerPage = () => {
//   const { fileName } = useParams();
//   const [fileData, setFileData] = useState(null);
//   const [section, setSection] = useState('current');
//   const navigate = useNavigate();

//   const api_Url = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchFileData = async () => {
//       try {
//         const response = await fetch(`${api_Url}/uploads/${fileName}`);
        
//         if (response.ok) {
//           setFileData({ pdfUrl: `${api_Url}/uploads/${fileName}` });
//         } else {
//           console.error('Error fetching file:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching file:', error);
//       }
//     };

//     if (fileName) {
//       fetchFileData();
//     }
//   }, [fileName]);

//   const renderSectionContent = () => {
//     switch (section) {
//       case 'current':
//         return fileData ? (
//           <embed
//             src={fileData.pdfUrl}
//             type="application/pdf"
//             className="w-full h-full"
//             style={{ border: 'none' }}
//           />
//         ) : (
//           'Loading...'
//         );
//       case 'back':
//         return <p>Previous files or history related to this file could go here.</p>;
//       case 'upcoming':
//         return <p>Upcoming related documents or updates about this file could go here.</p>;
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="h-screen bg-black text-white flex flex-col">
//       {/* Top buttons */}
//       <div className="flex justify-center gap-4 p-4 bg-gray-800">
//         <button
//           onClick={() => setSection('back')}
//           className={`px-4 py-2 ${section === 'back' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
//         >
//           Prev
//         </button>
//         <button
//           onClick={() => setSection('current')}
//           className={`px-4 py-2 ${section === 'current' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
//         >
//           Current
//         </button>
//         <button
//           onClick={() => setSection('upcoming')}
//           className={`px-4 py-2 ${section === 'upcoming' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
//         >
//           Upcoming
//         </button>
//       </div>

//       {/* PDF viewer */}
//       <div className="flex-1 flex justify-center items-center p-4 bg-gray-900">
//         <div className="w-full h-full max-w-full max-h-full overflow-auto bg-gray-900 rounded-lg shadow-lg">
//           {renderSectionContent()}
//         </div>
//       </div>

//       {/* Go Back Button */}
//       <button
//         onClick={() => navigate(-1)}
//         className="mt-4 mx-auto px-6 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors duration-300"
//       >
//         Go Back
//       </button>
//     </div>
//   );
// };

// export default FileViewerPage;







// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Document, Page, pdfjs } from 'react-pdf';

// // Set the PDF.js worker path
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// const FileViewerPage = () => {
//   const { fileName } = useParams();
//   const [fileData, setFileData] = useState(null);
//   const [numPages, setNumPages] = useState(null);
//   const [section, setSection] = useState('current');
//   const navigate = useNavigate();

//   const api_Url = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchFileData = async () => {
//       try {
//         const response = await fetch(`${api_Url}/uploads/${fileName}`);
//         if (response.ok) {
//           setFileData({ pdfUrl: `${api_Url}/uploads/${fileName}` });
//         } else {
//           console.error('Error fetching file:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching file:', error);
//       }
//     };

//     if (fileName) {
//       fetchFileData();
//     }
//   }, [fileName]);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   // Helper function to calculate page width
//   const getPageWidth = () => {
//     return window.innerWidth * 0.9; // Adjust width percentage as needed
//   };

//   return (
//     <div className="h-screen bg-black text-white flex flex-col">
//       {/* Top buttons */}
//       <div className="flex justify-center gap-4 p-4 bg-gray-800">
//         <button
//           onClick={() => setSection('back')}
//           className={`px-4 py-2 ${section === 'back' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
//         >
//           Prev
//         </button>
//         <button
//           onClick={() => setSection('current')}
//           className={`px-4 py-2 ${section === 'current' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
//         >
//           Current
//         </button>
//         <button
//           onClick={() => setSection('upcoming')}
//           className={`px-4 py-2 ${section === 'upcoming' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
//         >
//           Upcoming
//         </button>
//       </div>

//       {/* PDF viewer */}
//       <div className="flex-1 flex justify-center items-center p-4 bg-gray-900 overflow-auto">
//         <div className="w-full h-full max-w-full max-h-full bg-gray-900 rounded-lg shadow-lg">
//           {fileData ? (
//             <Document
//               file={fileData.pdfUrl}
//               onLoadSuccess={onDocumentLoadSuccess}
//               className="w-full h-full"
//             >
//               {Array.from(new Array(numPages), (el, index) => (
//                 <Page
//                   key={index}
//                   pageNumber={index + 1}
//                   width={getPageWidth()} // Adjust width dynamically
//                   className="my-0" // Remove vertical margins
//                 />
//               ))}
//             </Document>
//           ) : (
//             'Loading...'
//           )}
//         </div>
//       </div>

//       {/* Go Back Button */}
//       <button
//         onClick={() => navigate(-1)}
//         className="mt-4 mx-auto px-6 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors duration-300"
//       >
//         Go Back
//       </button>
//     </div>
//   );
// };

// export default FileViewerPage;






import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSwipeable } from 'react-swipeable'; // Import the swipeable hook

// Set the PDF.js worker path
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const FileViewerPage = () => {
  const { fileName } = useParams();
  const [fileData, setFileData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // Track the current page
  const [section, setSection] = useState('current');
  const navigate = useNavigate();

  const api_Url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchFileData = async () => {
      try {
        const response = await fetch(`${api_Url}/uploads/${fileName}`);
        if (response.ok) {
          setFileData({ pdfUrl: `${api_Url}/uploads/${fileName}` });
        } else {
          console.error('Error fetching file:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    if (fileName) {
      fetchFileData();
    }
  }, [fileName]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Helper function to calculate page size dynamically
  const getPageSize = () => {
    const height = window.innerHeight * 0.1; // Adjust height percentage as needed
    const width = window.innerWidth * 0.9;  // Adjust width percentage as needed
    return { width, height };
  };

  const { width, height } = getPageSize();

  // Swipe handler
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      }
    },
    onSwipedRight: () => {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="h-screen bg-black text-white flex flex-col">
      {/* Top buttons */}
      <div className="flex justify-center gap-4 p-4 bg-gray-800">
        <button
          onClick={() => setSection('back')}
          className={`px-4 py-2 ${section === 'back' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
        >
          Prev
        </button>
        <button
          onClick={() => setSection('current')}
          className={`px-4 py-2 ${section === 'current' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
        >
          Current
        </button>
        <button
          onClick={() => setSection('upcoming')}
          className={`px-4 py-2 ${section === 'upcoming' ? 'bg-blue-600' : 'bg-gray-700'} text-white rounded-lg transition-colors duration-300`}
        >
          Upcoming
        </button>
      </div>

      {/* PDF viewer with swipe functionality */}
      <div
        className="flex-1 flex justify-center items-center p-4 overflow-auto"
        {...handlers} // Attach swipe handlers
      >
        <div
            className="h-full  max-h-fullrounded-lg shadow-lg" 
        >
          {fileData ? (
            <Document
              file={fileData.pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className=" h[20px]"
            >
              <Page
                key={pageNumber}
                pageNumber={pageNumber}
                width={width}
                height={height}
                // className="mb-96" // Remove vertical margins
              />
            </Document>
          ) : (
            'Loading...'
          )}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-center gap-4 p-4 bg-gray-800">
        <button
          onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
          className="px-4 py-2 bg-gray-700 text-white rounded-lg transition-colors duration-300"
        >
          Previous
        </button>
        <button
          onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages))}
          className="px-4 py-2 bg-gray-700 text-white rounded-lg transition-colors duration-300"
        >
          Next
        </button>
      </div>

      {/* Go Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mt-4 mx-auto px-6 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors duration-300"
      >
        Go Back
      </button>
    </div>
  );
};

export default FileViewerPage;

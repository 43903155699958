import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const FilesPage_front = () => {
  const { personName } = useParams();
  const [fileTitles, setFileTitles] = useState([]);


  const apiUrl = process.env.REACT_APP_API_URL;

  // const api_Url = 'https://application.dosso21.com//api/skillontime/getAll/images'


  useEffect(() => {
  
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${apiUrl}/files/${personName}`, {
          method: 'GET', // This is the default method, so it can be omitted, but it's good practice to specify it.
          headers: {
            'Content-Type': 'application/json' // Correct Content-Type for JSON payloads; generally not needed for GET requests.
          }
        });

        if (response.ok) {
          const data = await response.json();
          const titles = data.map((file) => ({
            title: file.title,
            id: file._id,
            fileName: file.fileName,
          }));
          setFileTitles(titles);
        } else {
          console.error('Error fetching files:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    if (personName) {
      fetchFiles();
    }
  }, [personName]);

  return (
    <div className="min-h-screen flex flex-col bg-black p-4 sm:p-6">
      <h2 className="text-3xl sm:text-4xl font-extrabold mb-4 sm:mb-6 text-white shadow-lg p-2 sm:p-3 rounded-lg text-center">
        PDF Titles for {personName}
      </h2>
      <div className="bg-gray-900 p-6 sm:p-8 rounded-lg shadow-2xl mx-auto w-full max-w-md sm:max-w-2xl">
        {fileTitles.length > 0 ? (
          <ul>
            {fileTitles.map(({ title, id, fileName }) => (
              <li
                key={id}
                className="mb-3 sm:mb-4 bg-gray-800 p-3 sm:p-4 rounded-lg text-white text-base sm:text-lg"
              >
                <Link to={`/files/${personName}/view/${fileName}`} className="hover:underline">
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-white text-lg sm:text-xl font-semibold">
            No files found for {personName}.
          </p>
        )}
      </div>
    </div>
  );
};

export default FilesPage_front;
